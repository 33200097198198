<template>
    <div class="row-between navbar">
        <a href="/" class="logo-navbar">
            <img src="../assets/Logo.png" alt="Logo" >
        </a>
        <nav :class="['list-navbar', { 'open': isMenuOpen }]">
            <ul class="row-between">
                <li>
                    <router-link to="/">{{ $t('button.navbar[8]')}}</router-link>
                    <div></div>
                </li>
                <li>
                    <router-link to="/products/implants">{{ $t('button.navbar[1]')}}</router-link>
                    <div></div>
                </li>
                <li>
                    <router-link to="/products/matrice">{{ $t('button.navbar[0]')}}</router-link>
                    <div></div>
                </li>
                <li>
                    <router-link to="/products/actionneurs-capteurs">{{ $t('button.navbar[2]')}}</router-link>
                    <div></div>
                </li>
                <li>
                    <router-link to="/logiciel/micecloud">{{ $t('button.navbar[6]')}}</router-link>
                    <div></div>
                </li>
                <li>
                    <router-link to="/mission">{{ $t('button.navbar[3]')}}</router-link>
                    <div></div>
                </li>
                <li>
                    <router-link to="/contact">{{ $t('button.navbar[7]')}}</router-link>
                </li>
            </ul>
        </nav>
        <div class="right-navbar">
            <div class="connexion-navbar">
                <router-link to="/login">
                    <v-icon style="font-size: 30px; margin-right: 15px;">mdi-account</v-icon>
                    {{ $t('button.navbar[5]')}}
                </router-link>
            </div>
            <!-- Burger Menu Icon -->
            <div class="burger-menu" @click="toggleMenu">
                <v-icon style="font-size: 30px;">mdi-menu</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isMenuOpen: false // Gère l'état du menu burger (ouvert/fermé)
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        }
    }
}
</script>


<style scoped>

.navbar {
    border-bottom: solid 1px #777;
    width: 100%;
    padding: 12px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2000;
}

.logo-navbar {
    width: 10%;
}

.logo-navbar img {
    width: 230px;
    max-width: initial;
}

.list-navbar {
    /* margin-right: 10%; */
    align-content: center;
    width: 50%;
}

.list-navbar ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.list-navbar ul li a {
    text-decoration: none;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.list-navbar li div{
    position: relative;
    width: 0px;
    height: 2px;
    top: 5px;
    background-color: black;
    transition: width 0.5s ease; /* Animation sur la hauteur */
}

.list-navbar li:hover div {
  width: 100%; /* Hauteur finale lors de l'animation */
}


/* Container for both the connexion link and the burger menu */
.right-navbar {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 15%;
}

/* Connexion styles */
.connexion-navbar {
    margin-right: 20px;
}

.connexion-navbar a {
    text-decoration: none;
    color: var(--gris);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    align-content: center;
    font-weight: 500;
    margin-right: 0;
}

/* Burger Menu Styles */
.burger-menu {
    display: none;
    cursor: pointer;
}

/* Menu ouvert en mobile */
.list-navbar.open {
    display: block;
    position: absolute;
    top: 72px; /* Ajustez selon la hauteur de la navbar */
    left: 0;
    background-color: var(--background);
    width: 80%;
    z-index: 10;
    border-end-end-radius: 20px;
}

.list-navbar.open ul {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.list-navbar.open ul li {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 5px;
    border-bottom: solid 0.5px #b1b1b1;
}

@media (max-width: 1700px) {
    /* Masquer la liste de navigation normale et afficher le menu burger */
    .list-navbar{
        width: 60%;
    }
}

@media (max-width: 1600px) {
    /* Masquer la liste de navigation normale et afficher le menu burger */
    .list-navbar{
        width: 60%;
        margin-right: 0;
    }
}

/* Adaptations pour écrans plus petits */
@media (max-width: 1350px) {
    /* Masquer la liste de navigation normale et afficher le menu burger */
    .list-navbar ul {
        display: none;
    }

    .burger-menu {
        display: block;
    }

    .list-navbar.open ul {
        
        display: flex;
    }
    .list-navbar{
        margin: 0px;
        opacity: 0;
        transform: translateY(-20px);
        transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out; /* Transition pour l'apparition */
        /* width: 100%; */
    }
    .list-navbar.open{
        opacity: 1;
        transform: translateY(0); /* Retour à la position initiale */
    }
    .list-navbar ul li a{
        text-align: left;
    }
    .right-navbar{
        width: 30%;
    }
}

@media (max-width: 960px) {
    /* Masquer la liste de navigation normale et afficher le menu burger */
    .connexion-navbar{
        display: none;
    }
}

</style>